<template>
    <div>
        <template v-if="isAuthenticated">
            <div v-if="!status" class="mt-3 buy-course-link cursor-pointer univ-btn w-100">
                <a @click="buyCartItem(reqItem)"> <b-spinner v-if="buyBtnLoading" small></b-spinner> <span class="icon-payment"></span>  Buy Now </a>
            </div>
        </template>
        <template v-else >
            <div class="mt-3 buy-course-link cursor-pointer univ-btn w-100">
                <a v-b-modal.authenticate @click="actionBtn"> <span class="icon-payment"></span> Buy Now </a>
            </div>
        </template>
    </div>
</template>

<script>
import store from "@/store"
import { mapGetters, mapActions } from 'vuex'
export default {
    name: "ActionBuy",
    props:{
        itemData:{
            required: true,
            type:[Array,Object],
        }
    },
    data() {
        return {
            status: this.itemData.purchase,
            isAuthenticated: store.getters.authenticated,
            reqItem:{
                item_id: this.itemData.item_id,
                item_type: this.itemData.item_type,
                quantity: this.itemData.quantity,
                user_id: this.itemData.user_id
            },
            actionType:"",
        };        
    },
    computed: {
        ...mapGetters(["buyBtnLoading"]),
    },
    mounted(){
        let app = this;
        app.$root.$on("authentication-status", ($event) => {
            app.isAuthenticated = $event;
        });
    },
    methods:{
        ...mapActions(["buyCartItem"]),
        actionBtn(){
            this.actionType = "buy";
        }
    },
    watch: {
        itemData(value){
            let app = this;
            app.reqItem.item_id = app.$route.params.id;
            app.reqItem.item_type = value.item_type;
            app.reqItem.quantity = value.quantity;
            app.reqItem.user_id = value.user_id;
            app.status  = value.purchase;
        },
        isAuthenticated(value) {
            let app = this;
            if(value && app.actionType == 'buy') {
                app.reqItem.user_id = store.getters.user.id;
                app.buyCartItem(app.reqItem);
            }
        },
    },
}
</script>