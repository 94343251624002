import axios from 'axios';
import api from '@/services/api'
import router from "@/router"
const state = {
    userCart: [],
    cartItems: [],
    currency:"",
    paymentType:"",
    
    courseCart:[],
    assetCart:[],

    itemsSummary:[],
    cartBtnLoading: false,
    buyBtnLoading: false,
    selectedItem:[],

    discountCode:"",
    amountDiscount:0,
    cartTotalDiscount:0,
}
const mutations = {
    UPDATE_CART_ITEMS (state, payload) {
        state.userCart  = payload.user_cart;
        state.cartItems = payload.cart_items;
        state.currency  = payload.currency;

        state.cartBtnLoading = false;
        state.buyBtnLoading = false;
        // Discount
        if(Array.isArray(state.cartItems)){
            state.cartTotalDiscount = state.cartItems.reduce((acc, cartItem) => {
                let item_price = cartItem.price;
                if(cartItem.is_offer){
                    item_price = cartItem.offer_price;
                }
                return (cartItem.quantity * item_price ) + acc;
            }, 0).toFixed(2) - state.amountDiscount;
        }
    },
    UPDATE_DISCOUNT (state, payload) {
        state.discountCode      = payload.code;
        state.amountDiscount    = payload.amount;
    },
    UPDATE_CART_BTN_STATUS (state, status) {
        state.cartBtnLoading = status;
    },
    UPDATE_BUY_BTN_STATUS (state, status) {
        state.buyBtnLoading = status;
    },
    UPDATE_PAYMENT_TYPE (state, payload) {
        state.paymentType  = payload;
    },
    SELECTED_CART_ITEMD (state, item_id) {
        if(state.cartItems){
            state.cartItems.find(function (entry) {
                if(item_id == entry.item_id){
                    state.selectedItem  = entry;
                }
            })
        }else{
            state.cartTotalDiscount = 0;
        }
    },
    REMOVED_CART_ITEMD (state) {
        state.selectedItem  = [];     
    },
}
const actions = {
    getCartItems ({ commit }, userID) {
        axios.get(api.getUrl('/cart/'+userID)).then((response) => {
            if(response.data.success){
                commit('UPDATE_CART_ITEMS', response.data.data)
            }
        });
    },
    getCouponDiscount ({ commit }, discount) {
        commit('UPDATE_DISCOUNT', discount);
    },
    addCartItem ({ commit }, cartItem) {
        commit('UPDATE_CART_BTN_STATUS', true)
        axios.post(api.getUrl('/cart/store'), cartItem).then((response) => {
            if(response.data.success){
                commit('UPDATE_CART_ITEMS', response.data.data)
                commit('SELECTED_CART_ITEMD', cartItem.item_id)
            }
        });
    },
    buyCartItem ({ commit }, cartItem) {
        commit('UPDATE_BUY_BTN_STATUS', true)
        axios.post(api.getUrl('/cart/store'), cartItem).then((response) => {
            if(response.data.success){
                commit('UPDATE_CART_ITEMS', response.data.data)
                router.push({ name: "CartCheckout" })
            }
        });
    },
    removeCartItem ({ commit }, cartItem) {
        commit('UPDATE_CART_BTN_STATUS', true)
        return new Promise((resolve, reject) => {
        axios.post(api.getUrl('/cart/delete'),{ id:cartItem.id,item_id:cartItem.item_id,item_type:cartItem.type }).then(function (response) {
            if(response.data.success){
                commit('REMOVED_CART_ITEMD') 
                commit('UPDATE_CART_ITEMS', response.data.data)
                commit('SELECTED_CART_ITEMD', cartItem.item_id)
                if(response.data.data.length === 0){
                    reject();
                    if(router.history.current.name ==='CartCheckout'){
                        router.push({ name: "Home" }).catch(()=>{});
                    }else{
                        router.push({ name: router.history.current.name }).catch(()=>{});
                    }
                }
                resolve();
            }
        });
    });
    },
    setPaymentType({ commit }, option){
        commit('UPDATE_PAYMENT_TYPE', option)
    },
}
const getters = {
    cartBtnLoading: state => state.cartBtnLoading,
    buyBtnLoading: state => state.buyBtnLoading,
    userCart: state => state.userCart,
    cartItems: state => state.cartItems,
    currency: state => state.currency,
    cartTotal: state => {
        if(Array.isArray(state.cartItems)){
            return state.cartItems.reduce((acc, cartItem) => {
                let item_price = cartItem.price;
                if(cartItem.is_offer){
                    item_price = cartItem.offer_price;
                }
                return (cartItem.quantity * item_price ) + acc;
            }, 0).toFixed(2);
        }return 0;
    },
    
    discountCode:state => state.discountCode,
    cartTotalDiscount: state => state.cartTotalDiscount,
    amountDiscount: state => state.amountDiscount,

    cartQuantity: state => {
        if(Array.isArray(state.cartItems)){
            return state.cartItems.reduce((acc, cartItem) => {
                return cartItem.quantity + acc;
            }, 0);
        }return 0;
    },
    cartItemSummary: state => {
        state.itemsSummary = [];
        if(Array.isArray(state.cartItems)){
            state.cartItems.forEach((value) => {
                let item = {};
                item.item_id        =  value.item_id;
                item.item_type      =  value.item_type;
                item.price          =  value.price;
                item.offer_price    =  value.offer_price;
                item.qty            =  value.quantity;
                state.itemsSummary.push(item);
            });
        }
        return state.itemsSummary;
    },
    courseCartItems: state => {
        if(Array.isArray(state.cartItems)){
            state.cartItems.forEach((value) => {
                if(value.item_type ==='course'){
                    state.courseCart.push(value.item_id);
                }
            });
        }
        return state.courseCart;
    },
    assetCartItems: state => {
        state.assetCart = [];
        if(Array.isArray(state.cartItems)){
            state.cartItems.forEach((value) => {
                if(value.item_type ==='asset'){
                    state.assetCart.push(value.item_id);
                }
            });
        }
        return state.assetCart;
    },
    getSelecteItem: state => state.selectedItem,
}
export default {
    state,
    mutations,
    actions,
    getters
}