import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"
import createPersistedState from "vuex-persistedstate"
//import * as Cookies from "js-cookie"

import router from "@/router"
import Auth from "@/store/modules/auth"
import Cart from "@/store/modules/cart"
import Course from "@/store/modules/course"
import Wishlist from "@/store/modules/wishlist"
import Helper from "@/services/helper"

Vue.use(Vuex)
let expiryTime = new Date();
expiryTime.setTime(expiryTime.getTime() + (604800 * 1000));// 60 => 1 min

const vuexStore = new Vuex.Store({
  // plugins: [
  //   createPersistedState({
  //     paths: ["Auth","Cart","Wishlist","Course"],
  //     key:"auth",
  //     storage: {
  //         getItem: (key) => Cookies.get(key),
  //         setItem: (key, value) =>Cookies.set(key, value, { expires: expiryTime, secure: false }),
  //         removeItem: (key) => Cookies.remove(key),
  //     }
  //   }),
  // ],
  plugins: [createPersistedState()],
  state: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {
    Auth,
    Cart,
    Wishlist,
    Course,
  },
})
axios.interceptors.request.use((config) => {
    config.headers['Authorization'] = config.headers['Authorization'] ?? (vuexStore.getters.accessToken != "" ? "Bearer " + vuexStore.getters.accessToken : "");
    return config;  
});
axios.interceptors.response.use(response => response, function (error) {
    if (error?.response?.status == 401) {
        Helper.clearToken()
    }
    if (error?.response?.data?.message == 'Unauthorized' || error?.response?.status == 418) {
        Helper.clearToken()
        router.push({ name: "Home" }).catch(()=>{});
    }
    return Promise.reject(error);
});
export default vuexStore