<template>
    <b-modal id="AssetQuickViewModal" centered size="lg" :hide-footer="hideFooter">
        <div class="assets-buyModal py-4">
            <div class="back-btn mb-4">
                <a @click="$bvModal.hide('AssetQuickViewModal')" >
                    <span class="backIcon">
                        <span class="icon-arrow"></span>
                    </span>
                    Back
                </a>
            </div>
            <div class="row">
                <div class="col-xl-4 col-lg-4">
                    <div class="assetsImage">
                        <img :src="item.image" class="img-fluid">
                    </div>
                </div>
                <div class="col-xl-8 col-lg-8">
                    <div class="assetsContent">
                        <span class="category brand-color">{{item.category}}</span>
                        <h3>{{item.title}}</h3>
                        <div v-html="item.description"></div>
                        <div class="cta-assets row">
                            <div class="col-md-6">
                                <ActionWishlist :itemData="reqItem"/>
                            </div>
                            <div class="col-md-6">
                                <ActionCart :itemData="reqItem"/>
                            </div>
                            <div v-if="item.price !=0" class="col-md-12">
                                <ActionBuy :itemData="reqItem" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-if="item.images.length > 0">
                    <div class="assetsContent">
                        <div class="pt-3 example-images">
                            <h4>Examples</h4>
                            <ul>
                                <li v-for="(image,i) in item.images" :key="i" @click="index = i" >
                                    <img :src="image.src" class="img-fluid">
                                </li>
                            </ul>
                        </div>
                    </div>
                    <CoolLightBox :items="item.images" :index="index" @close="index = null"> </CoolLightBox>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
import {mapActions} from 'vuex'
import store from "@/store"
import axios from "axios";
import api from "@/services/api";

import ActionBuy from '@/components/ActionBuy'
import ActionCart from '@/components/ActionCart'
import ActionWishlist from '@/components/ActionWishlist'

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default{
    name: 'AssetQuickViewModal',
    components:{
        ActionBuy,
        ActionCart,
        ActionWishlist,
        CoolLightBox,
    },
    props: {
        assetItem:{
            required:true,
        }
    },
    data() {
        return {
            isAuthenticated: store.getters.authenticated,
            item: this.assetItem,

            reqItem: {
                user_id: store.getters.user.id,
                item_id: this.assetItem.asset_id,
                item_type: "asset",
                quantity: 1,
                purchase: false,
            },
            hideFooter:true,
            index: null
        };
    },
    mounted() {
        let app = this;
        app.$bvModal.show('AssetQuickViewModal')
        app.renderDetail();
        app.$root.$on("authentication-status", ($event) => {
            app.isAuthenticated = $event;
        });
        app.$root.$on("asset-view-modal", ($event) => {
            if(!$event){
                app.$bvModal.hide('AssetQuickViewModal')
            }
        });
    },
    methods: {
        async renderDetail() {
            let app = this;
            await axios.get(api.getUrl("/asset/" + app.item.slug + "/" + app.item.asset_id )).then(function (response) {
                if (response.data.success) {
                    document.title = response.data.data.title;
                    app.page_title = response.data.data.title;
                    app.page_description = response.data.data.description;

                    app.item = response.data.data;
                    app.reqItem.item_id  = app.item.asset_id;
                }
            }).finally(() => {
                
            });
        },
        // Add Items to cart
        ...mapActions(["addCartItem","buyCartItem","addWishlistItem","removeWishlistItem"]),
    },
    watch:{
        assetItem(value){
            let app = this;
            app.item = value;
            app.renderDetail();
        }
    }
}
</script>