import axios from 'axios';
import api from '@/services/api'

const state = {
    authenticated: false,
    accessToken: "",
    user:"",
    loginSource:"login-btn",
    appTokenStatus: false,
}
const getters = {
    authenticated: state => state.authenticated,
    accessToken: state => state.accessToken,
    user: state => state.user,
    loginSource: state => state.loginSource,
    appTokenStatus: state => state.appTokenStatus,
}
const mutations = {
    setAuthentication(state, status) {
        state.authenticated = status;
    },
    setAccessToken(state, token) {
        state.accessToken = token;
    },
    setUserData(state, data) {
        state.user = data;
    },
    setLoginSource(state, source) {
        state.loginSource = source;
    },
    setTokenStatus(state, source) {
        state.appTokenStatus = source;
    },
}
const actions = {
    getTokenStatus ({commit} ) {
        axios.get(api.getUrl('/app-init')).then((response) => {
            commit('setTokenStatus', response.data.success)
        });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}